<template>
  <div
    class="nav-wrapper fixed top-0 z-50 block h-[100dvh] w-auto bg-[var(--top-navigation-background)] text-[var(--top-navigation-text-color)] lg:relative lg:z-40 lg:h-auto lg:w-full [&_*:not(span)]:border-[var(--top-navigation-border-color)]"
    :class="{
      'active -left-[500px] lg:left-0': !isMobileMenuActive,
      'left-0 w-full': isMobileMenuActive,
      '!bg-transparent !text-[var(--top-navigation-text-color)]':
        isTransparent && !isMobileMenuVisible
    }"
  >
    <div
      class="container mx-auto h-[100dvh] lg:h-auto"
      :class="{
        'lg:border-t': !isTransparent
      }"
      data-testid="nav-wrapper"
      @click.self="isMobileMenuActive = false"
    >
      <div
        class="nav-inner z-20 flex h-[100dvh] w-[500px] max-w-full flex-col justify-between bg-[var(--top-navigation-background)] py-5 lg:h-auto lg:w-auto lg:py-0"
      >
        <div
          class="mobile-logo flex items-center justify-end border-b px-5 lg:hidden"
          :class="{ 'justify-between': logo }"
        >
          <nuxt-link :to="`/${locale}`">
            <CommonStrapiImage
              v-if="logo?.data?.attributes"
              :data="logo.data.attributes"
              class="mb-3 h-10 w-auto"
              data-testid="nav-logo"
            />
          </nuxt-link>

          <button
            class="-mr-5 -mt-4 p-2 pr-4 text-xl"
            data-testid="nav-close-btn"
            :aria-label="$t('header.close-menu')"
            @click="isMobileMenuActive = false"
          >
            <Icon name="ic:outline-close" />
          </button>
        </div>

        <div
          data-testid="nav"
          class="page-nav align-start flex w-full flex-1 flex-col overflow-y-auto bg-[var(--top-navigation-background)] py-3 text-lg lg:flex-none lg:flex-row lg:overflow-visible lg:py-0"
          :class="{
            active: isMobileMenuActive,
            'lg:absolute lg:left-0 lg:right-0 lg:top-0 lg:bg-black/10 lg:!pt-20 [&>*]:lg:!text-white':
              isTransparent,
            'backdrop-blur-sm':
              !isMobileMenuVisible && !isMobileMenuActive && isBlur
          }"
        >
          <div
            class="container mx-auto w-full"
            :class="{
              'border-t-2 !border-white/50': isTransparent
            }"
          >
            <ul class="lg:flex xl:-mx-3">
              <LayoutUiNavigationLink
                v-for="link in links"
                :key="link.id"
                :link="link"
                :level="1"
              />

              <li
                class="page-nav-hover-overlay invisible absolute left-0 top-0 h-[100dvh] w-screen bg-black/50 opacity-0 transition-opacity duration-300 lg:hidden"
                role="presentation"
                data-testid="nav-overlay"
                @click="isMobileMenuActive = false"
              ></li>
            </ul>
          </div>
        </div>
        <div
          id="teleport-language-switcher-mobile"
          class="border-t px-5 pt-3 lg:hidden"
        >
          <!-- will be teleported here -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { StrapiNavigation } from '@autobid/strapi-integration/typescript/strapi/Navigation'
import { useHeaderStore } from '@autobid/nuxt-pinia-store/store/useHeaderStore'
import { storeToRefs } from 'pinia'
import type { StrapiMedia } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import LayoutUiNavigationLink from './partials/UiNavigationLink.vue'

interface Props {
  links: StrapiNavigation
  logo?: StrapiMedia
  isTransparent?: boolean
  isBlur?: boolean
}

defineProps<Props>()

const { isMobileMenuActive, isMobileMenuVisible } = storeToRefs(
  useHeaderStore()
)

const { locale } = useI18n()
</script>

<style lang="scss">
.page-nav-hover-overlay {
  z-index: -1;
}

@media (max-width: 1024px) {
  .page-nav {
    &.active {
      .page-nav-hover-overlay {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .nav-wrapper {
    transition: 0.3s left;

    &.active {
      transition: 0.3s 0.2s left;

      .page-nav-hover-overlay {
        transition: 0.3s;
      }
    }

    .page-nav-hover-overlay {
      transition: 0.3s 0.2s;
    }
  }
}

@media (min-width: 1025px) {
  .page-nav {
    li {
      &:first-child {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      a {
        display: inline-block;
        text-align: center;

        &::before {
          // trick to preset the width of the link and avoid layout shift during hover
          display: block;
          content: attr(title);
          font-weight: 700;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }

      .submenu {
        a {
          text-align: left;
        }
      }

      &:hover {
        & > .submenu {
          opacity: 1;
          visibility: visible;
          margin-top: 0;
        }

        & > div {
          & > a {
            font-weight: 700;
          }
        }
      }
    }

    .with-children {
      li {
        &:hover {
          background: var(--color-nav-bg-hover);

          & > div {
            & > a {
              font-weight: 400;
              color: var(--color-nav-color-hover);
            }

            & > .nav-arrow span {
              border-color: var(--color-nav-color-hover);
            }
          }
        }
      }
    }
  }
}
</style>
