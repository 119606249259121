<template>
  <div class="desktop-logo-wrapper">
    <LayoutHeaderPartialsLogo
      :logo="data.data.layout?.header?.brand"
      data-testid="desktop-logo"
    />
  </div>

  <AutobidRenderComponents
    :components="data.data.layout.topMenu"
    class="hidden items-center py-4 pr-3 text-[var(--top-navigation-text-color)] lg:flex"
  />

  <div
    class="header-right-wrapper items-center text-[var(--top-navigation-text-color)] lg:mr-3 lg:flex lg:min-w-max lg:flex-1"
  >
    <div class="header-right">
      <component
        :is="img.url?.length ? CommonLink : 'span'"
        v-for="img in data.data.layout?.header?.rightSide ?? []"
        :key="img.id"
        v-bind="img.url?.length ? { href: img.url } : {}"
      >
        <CommonStrapiImage
          :data="img.image.data.attributes"
          class="h-16 w-auto"
          data-testid="desktop-header-cms-img"
        />
      </component>
    </div>

    <div
      id="teleport-language-switcher"
      class="hidden lg:block"
      data-testid="desktop-lang-switcher"
    >
      <!-- will be teleported here -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { HeaderProps } from '@autobid/ui/composables/usePageHeader'
import CommonLink from '@autobid/ui/components/common/Link.vue'

interface Props {
  data: HeaderProps
}

defineProps<Props>()
</script>

<style lang="scss" scoped>
@media (min-width: 1680px) {
  .desktop-logo-wrapper {
    // 1256px - container | 12px - first link padding
    min-width: calc((100vw - 1256px) / 2 - 16px);
  }
}
</style>
