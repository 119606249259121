<template>
  <div
    class="header sticky left-0 right-0 top-0 z-50 flex items-center bg-[var(--top-navigation-background)] py-2 transition lg:py-0 xl:text-lg"
    :class="{
      scrolled: scrollY > 0,
      '!bg-black/10 !text-white backdrop-blur-sm lg:backdrop-blur-none [&>*]:!text-white':
        isNavbarTransparentVisible,
      '!fixed': data.layout.isNavbarTransparent,
      'backdrop-blur-sm': data.layout.isNavbarBlur,
      'text-[var(--top-navigation-text-color)]': !isNavbarTransparentVisible,
      'transparent-nav-scrolled': scrollY >= ADMIN_NAV_HEIGHT,
      '!pt-1':
        canUserEditPages &&
        scrollY < ADMIN_NAV_HEIGHT &&
        isNavbarTransparentVisible,
      'lg:!bg-black/10':
        data.layout.isNavbarTransparent &&
        scrollY > 60 &&
        scrollY < TRANSPARENT_NAVBAR_THRESHOLD,
      'lg:!backdrop-blur-sm':
        data.layout.isNavbarBlur &&
        scrollY > 60 &&
        scrollY < TRANSPARENT_NAVBAR_THRESHOLD,
      'lg:!bg-black/0': data.layout.isNavbarTransparent && scrollY < 60
    }"
    data-testid="header"
  >
    <LayoutHeaderDesktop :data="{ data, callbackUrl }" />

    <LayoutHeaderMobile :data="{ data, callbackUrl }" />
  </div>

  <LayoutUiNavigation
    :links="data.navigation ?? {}"
    :logo="data?.layout?.header?.brand?.[0]?.image"
    :is-transparent="data?.layout.isNavbarTransparent"
    :is-blur="data?.layout.isNavbarBlur"
  />
</template>

<script lang="ts" setup>
import type { StrapiBuilderLayoutData } from '@autobid/strapi-integration/typescript/strapi/Page'
import { storeToRefs } from 'pinia'
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import { TRANSPARENT_NAVBAR_THRESHOLD } from '@autobid/ui/constants/TRANSPARENT_NAVBAR_THRESHOLD'
import { useCheckUserPermission } from '@autobid/ui/composables/useCheckUserPermission'

const ADMIN_NAV_HEIGHT = 30

interface Props {
  data: StrapiBuilderLayoutData
}

const props = defineProps<Props>()

const { scrollY } = storeToRefs(useBodyStore())

const isNavbarTransparentVisible = computed(() => {
  return (
    props.data.layout.isNavbarTransparent &&
    scrollY.value < TRANSPARENT_NAVBAR_THRESHOLD
  )
})

provide('isNavbarTransparentVisible', isNavbarTransparentVisible)

const route = useRoute()
const { checkUserPermission } = useCheckUserPermission()
const canUserEditPages = computed(() => checkUserPermission('edit_static'))
const callbackUrl = computed(() => `${route.path}?auth=1`)
</script>

<style lang="scss">
.header,
.nav-wrapper {
  a:hover:not(.nav-link, .lang-switcher-link),
  button:hover:not(.nav-link .lang-switcher-link) {
    color: var(--text-hover);
  }
}
</style>
